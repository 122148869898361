
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { AlertModel } from '@/store/models/DialogModel';
import J1lImg from '@/components/J1lImg.vue';
import ClickOutside from '@/directives/Directives';
@Component({
  components: { J1lImg },
  directives: { ClickOutside }
})
export default class extends Vue {
  $refs!: {};
  /* Properties */

  @Prop() alertOption!: AlertModel;
  /* Data */
  confirmMessage: string = 'Change';
  isCancelBtnDisabled: boolean = false;

  /* Methods */
  closeAlert() {
    this.$emit('close');
  }
  onConfirmation() {
    this.$emit('confirm', {
      Type: this.alertOption.Type,
      ItemId: this.alertOption.ItemId
    });
  }
  /* Mounted */
  async mounted() {
    this.isCancelBtnDisabled = this.alertOption.IsCancelBtnDisabled;
    if (this.alertOption.ConfirmationMessage) {
      this.confirmMessage = this.alertOption.ConfirmationMessage;
    }
  }
  /* Computed */

  created() {}
  /* Created */
}
