
declare global {
  interface Window {
    initMap: () => void;
  }
}
window.initMap = initMap;
function initMap(): void {
  // The location of Uluru
  const uluru = { lat: 33.29719074244352, lng: -111.9596241 };
  // The map, centered at Uluru
  const map = new globalThis.google.maps.Map(
    document.querySelector('#map') as HTMLElement,
    {
      zoom: 13,
      center: uluru
    }
  );

  // The marker, positioned at Uluru
  const marker = new globalThis.google.maps.Marker({
    position: uluru,
    map: map
  });
}
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class J1LContactDialog extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  numberClick() {
    window.open('tel:4802522125');
  }
  async openDialog() {
    this.dialog = true;
    if (!globalThis.google) {
      const plugin = document.createElement('script');
      plugin.setAttribute(
        'src',
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCf8dttldSSV_sltYdIugNhsTewVDj6NeA&callback=initMap&v=weekly'
      );
      document.head.appendChild(plugin);
    } else {
      setTimeout(() => {
        initMap();
      }, 300);
    }
  }
  closeDialog() {
    this.dialog = false;
  }
  getDirections() {
    window.open(
      'https://www.google.com/maps?saddr=My+Location&daddr=6885+W+Frye+Rd,+Chandler,+AZ+85226',
      '_blank'
    );
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
