
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  /* Properties */
  /* Store Actions */
  @Prop()
  dialogText!: string;
  @Prop()
  dialogTitle!: string;
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  /* Methods */
  showDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
