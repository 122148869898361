
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '../components/DefaultLayout.vue';
import { DateHelper } from '@/helpers/DateHelper';
@Component({
  components: { DefaultLayout }
})
export default class J1LDateIcon extends Vue {
  /* Properties */
  @Prop({ required: true })
  date!: Date;
  @Prop()
  colorClass!: string;
  @Prop()
  borderClass!: string;
  @Prop()
  shouldUseNullDate!: boolean;
  /* Store Actions */
  /* Watchers */
  @Watch('date')
  onDateChange() {
    this.setDate();
  }
  /* Data */
  month: string = '';
  day: number = 0;
  dayOfWeek: string = '';
  /* Utility Functions */
  setDate() {
    let date = new Date(this.date);
    this.month = DateHelper.GetMonth(date);
    this.day = date.getDate();
    this.dayOfWeek = DateHelper.GetDayOfWeek(date);
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.setDate();
  }
  /* Created */
}
